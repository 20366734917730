import React from "react";

const CorporateTourFaq = () => (
    <React.Fragment>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What kind of corporate events do you host?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "We cater to a variety of corporate events, including team-building retreats, conferences, incentive trips, and product launches."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What adventure activities do you offer?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "We offer a range of outdoor activities like go-karting, ziplining, sky swinger, rope courses, frisbee and more. Indoor activities are also available."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What kind of conference facilities do you have?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Our spacious conference halls are equipped with state-of-the-art audio-visual technology and can accommodate groups of all sizes."
                            }
                        }, {
                            "@type": "Question",
                            "name": "Do you offer accommodation on-site?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, we have The Twin Towers Hotel, featuring comfortable and well-appointed rooms to accommodate your entire team."
                            }
                        }, {
                            "@type": "Question",
                            "name": "How can I customize a corporate retreat package?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Simply contact our sales team to discuss your requirements. We'll work with you to create a personalized plan that fits your budget and goals."
                            }
                        }]
                    }
                )
            }}
        />
    </React.Fragment>
);
export default CorporateTourFaq;