import Link from "next/link";
import { FaWhatsapp } from "react-icons/fa";
const WhatsapChatBot = () => {
    return (
        <div>
            <Link href={"https://wa.me/9318192601"} className="btn-whatsapp-pulse" target="blank">
                <FaWhatsapp
                        style={{
                          color: "white",
                          height: "35px",
                          width: "35px",
                          position:'absolute'
                        }}
                      />
            </Link>
        </div>
    );
}
export default WhatsapChatBot;
