import React from "react";

const SiteName = () => (
    <React.Fragment>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                    {
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        "name": "Adventure Resort",
                        "url": "https://www.adventureresortkufri.com/",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "{search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    }
                )
            }}
        />
    </React.Fragment>
);
export default SiteName;