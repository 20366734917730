import React from 'react'
import Head from 'next/head'
import PageViewPixel from './facebook/PageViewPixel'
import ViewTicketsContent from './facebook/ViewTicketsContent'

export default function Pixel({ name }) {

  return (
    <Head>
      {name === 'PageViewPixel' && <PageViewPixel />}
      {name === 'ViewTicketsContent' && <ViewTicketsContent />}
    </Head>
  )
}