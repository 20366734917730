import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Head from "next/head";
import Loader from "./Reusable/Loader/Loader";
import { useRouter } from "next/router";
import { gsap } from "gsap/dist/gsap";
import { ScrollSmoother } from "gsap/dist/ScrollSmoother";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { SplitText } from "gsap/dist/SplitText";
import { CartProvider } from "../context/CartContext";
import Link from "next/link";

if (process.client) {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
}

// if (process.client) {
//     gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CSSRulePlugin);
// }

// var $ = require("jquery");
// if (typeof window !== "undefined") {
//     window.$ = window.jQuery = require("jquery");
// }

ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    pinType: "fixed",
});

function Layout({ children }) {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);
        var splitHide = new SplitText(".animate_title", { type: "words", linesClass: "linechild hide" });
        var split = new SplitText(".animate_title", {
            type: "words",
        });
        gsap.utils.toArray(split.words).forEach(function (elem) {
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () {
                    gsap.fromTo(
                        elem,
                        { xPercent: -150, autoAlpha: 0 },
                        {
                            duration: 1,
                            xPercent: 0,
                            autoAlpha: 1,
                            ease: "power4",
                            overwrite: "auto",
                        }
                    );
                },
                onLeave: function () {
                    gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
                },
                onEnterBack: function () {
                    gsap.fromTo(
                        elem,
                        { xPercent: 100, autoAlpha: 0 },
                        {
                            duration: 1,
                            xPercent: 0,
                            autoAlpha: 1,
                            ease: "power4",
                            overwrite: "auto",
                        }
                    );
                },
                onLeaveBack: function () {
                    gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
                },
            });
        });
    });

    useEffect(() => {
        gsap.utils.toArray("#revealUp").forEach(elem => {
            elem.split = new SplitText(elem, {
                type: "lines,words,chars",
                linesClass: "split-line"
            })
            elem.anim = gsap.from(elem.split.lines, {
                scrollTrigger: {
                    trigger: elem,
                    toggleActions: "restart pause resume reverse",
                    start: "50% 70%",
                },
                duration: 0.6,
                ease: "circ.out",
                yPercent: 100,
                stagger: 0.2,
            })
        });
    });

    useEffect(() => {
        gsap.utils.toArray(".desc_animate").forEach((elem) => {
            elem.split = new SplitText(elem, {
                type: "lines,words,chars",
                linesClass: "split-line",
            });

            gsap.from(elem.split.lines, {
                scrollTrigger: {
                    trigger: elem,
                    toggleActions: "restart pause resume reverse",
                    start: "50% 80%",
                },
                duration: 0.6,
                autoAlpha: 0,
                ease: "circ.out",
                yPercent: 0,
                stagger: 0.2,
            });
        });
    });

    useEffect(() => {
        var tl = gsap.timeline();
        gsap.utils.toArray(".wrapimage").forEach((element) => {
            var animation = gsap.timeline({ paused: true });
            animation.to(element, { scale: 1.1, duration: .5, ease: "power1.inOut" });
            element.addEventListener("mouseenter", function () { animation.play(); });
            element.addEventListener("mouseleave", function () { animation.reverse(); });
        });

    });

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        let revealContainers = document.querySelectorAll(".reveal_insta_image");

        revealContainers.forEach((container) => {
            let image = container.querySelector("img");
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: container,
                    start: "top 120%",
                    toggleActions: "play none none reverse"
                }
            });

            tl.set(container, { autoAlpha: 1 });
            tl.from(container, 3, {
                yPercent: 100,
                ease: "Expo.easeOut"
            });
            tl.from(image, 3, {
                yPercent: -100,
                delay: -3,
                ease: "Expo.easeOut"
            });
        });

    });

    // useEffect(() => {
    //     gsap.defaults({ ease: "power3" });
    //     gsap.set(".animate_box", { y: 100 });

    //     ScrollTrigger.batch(".animate_box", {
    //         onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, stagger: { each: 0.10, grid: [1, 3] }, overwrite: true }),
    //         onLeave: batch => gsap.set(batch, { opacity: 0, y: -100, overwrite: true }),
    //         onEnterBack: batch => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.10, overwrite: true }),
    //         onLeaveBack: batch => gsap.set(batch, { opacity: 0, y: 100, overwrite: true })
    //     });
    // });

    useEffect(() => {
        const image = document.querySelector('.helicopter_reveal_image');
        ScrollTrigger.create({
            trigger: image,
            toggleClass: 'helicopter_image_active',
            start: "top 90%",
        })
    });

    useEffect(() => {
        ScrollTrigger.matchMedia({

            // desktop
            "(min-width: 769px)": function () {

                gsap.to(".bg_meeting_img", {
                    scrollTrigger: {
                        trigger: ".bg_meeting_img",
                        toggleActions: "restart pause reverse pause",
                        scrub: 1,
                        start: "top bottom",
                        end: "center top"
                    },
                    backgroundPosition: "0px -500px", ease: "none"
                })
            },

            "(max-width: 768px)": function () {
                gsap.to(".bg_meeting_img", {
                    scrollTrigger: {
                        trigger: ".bg_meeting_img",
                        toggleActions: "restart pause reverse pause",
                        scrub: 1,
                        start: "top bottom",
                        end: "center top"
                    },
                    backgroundPosition: "0px -100px", ease: "none"
                })
            },
            "all": function () {
            }
        })
    });

    useEffect(() => {
        const subhead_animate = document.querySelectorAll(".subhead_animate");
        subhead_animate.forEach(subhead => {
            subhead.split = new SplitText(subhead, {
                type: "lines,words,chars",
                linesClass: "split-line"
            });

            subhead.anim = gsap.from(subhead.split.lines, {
                scrollTrigger: {
                    trigger: subhead,
                    toggleActions: "restart pause resume reverse",
                    start: "50% 70%",
                },
                duration: 0.6,
                ease: "circ.out",
                yPercent: 100,
                stagger: 0.2,
                opacity: 0
            });
        });

    });

    const router = useRouter();
    if (router.pathname != "/e-card-baldevthakur" && router.pathname != "/e-card-prateekthakur" && router.pathname != "/prateekthakursig" && router.pathname != "/rameshdograsig" && router.pathname != "/payment/success/[orderId]" && router.pathname != "/payment/cancel/[orderId]")

        return (
            <CartProvider>
                <main key="public_route">
                    <link rel="icon" href="/favicon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <Header />
                    {children}
                    <Footer />
                </main>
            </CartProvider>
        )
    else {
        return (
            <CartProvider>
                <main key="private_route">
                    <link rel="icon" href="/favicon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    {children}
                </main>
            </CartProvider>
        )
    }
}

export default Layout;
