import '../styles/globals.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from '../components//Layout'
import { useState, useEffect, useRef } from 'react';
import Script from 'next/script'
import WhatsapChatBot from '../components/WhatsapChatBot/WhatsapChatbot';
import Loader from '../components/Reusable/Loader/Loader';
import Head from 'next/head';
import Pixel from '../components/Pixel';
import BreadcrumbSchema from '../components/Schema/Resortschema/breadcrumb';
import LocatbusinessSchema from '../components/Schema/Resortschema/local-business';
import OrganisationSchema from '../components/Schema/Resortschema/organisation';
import SiteName from '../components/Schema/Resortschema/site-name';
import HeatMap from '../components/Schema/Resortschema/heat-map';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyApp = ({ Component, pageProps, cart }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <Head>
        <meta name="facebook-domain-verification" content="hq4ey2pm6ljagl88wv4ofqs5s6jcdf" />
      </Head>

      {/* {loading ? (
        <Loader />
      ) : ( */}
      <Layout>
        {loading && <Loader />}
        <Component {...pageProps} cart={cart} />
        {/* <Chatbot /> */}
        <WhatsapChatBot />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
      </Layout>
      {/* )} */}

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y2NZ1BSVK7"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-Y2NZ1BSVK7');
            `,
        }}
      />

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-253333997-1"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-253333997-1');
            `,
        }}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-NNXRQFM');
            `,
        }}
      />

      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NNXRQFM"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>

      <BreadcrumbSchema />
      <LocatbusinessSchema />
      <OrganisationSchema />
      <SiteName />
      <HeatMap />
    </>

  )
}

MyApp.getServerSideProps = async (ctx) => {
  const cart = JSON.parse(localStorage.getItem('cart')) || [];
  return {
    props: {
      cart,
    },
  };
};

export default MyApp

// const router = useRouter();
// const [loading, setLoading] = useState(false);
// const timerRef = useRef(null);

// useEffect(() => {
//   const handleStart = (url) => {
//     if (url !== router.asPath) {
//       setLoading(true);
//     }
//   };

//   const handleComplete = (url) => {
//     if (url === router.asPath && loading) {
//       // Clear the existing timer, if any
//       if (timerRef.current) {
//         clearTimeout(timerRef.current);
//       }
//       // Start a new timer to hide the loader after 300ms
//       timerRef.current = setTimeout(() => {
//         setLoading(false);
//       }, 300);
//     }
//   };

//   router.events.on('routeChangeStart', handleStart);
//   router.events.on('routeChangeComplete', handleComplete);
//   router.events.on('routeChangeError', handleComplete);

//   return () => {
//     // Clear the timer on unmount
//     if (timerRef.current) {
//       clearTimeout(timerRef.current);
//     }
//     router.events.off('routeChangeStart', handleStart);
//     router.events.off('routeChangeComplete', handleComplete);
//     router.events.off('routeChangeError', handleComplete);
//   };
// }, [router.asPath, loading]);