import React from "react";
import Head from "next/head";
import Organistion from "./Resortschema/organisation";
import Breadcrumb from "./Resortschema/breadcrumb";
import LocalBusiness from "./Resortschema/local-business";
import HeatMap from "./Resortschema/heat-map";
import SiteName from "./Resortschema/site-name";
import EducationalPackagesFaq from "./GroupSchema/EducationalPackagesFaq";
import CorporateTourFaq from "./GroupSchema/CorporateTourFaq";
import RestaurantFaq from "./GroupSchema/RestaurantFaq";

const Schema = ({ name }) => {
  return (
    <Head>
      {name === "Organistion" && <Organistion />}
      {name === "BreadcrumbList" && <Breadcrumb />}
      {name === "LocalBusiness" && <LocalBusiness />}
      {name === "SiteName" && <SiteName />}
      {name === "EducationalPackagesFaq" && <EducationalPackagesFaq />}
      {name === "CorporateTourFaq" && <CorporateTourFaq />}
      {name === "RestaurantFaq" && <RestaurantFaq />}
    </Head>
  );
};

export default Schema;