import Link from 'next/link';
import styles from './Buttons.module.scss'
import { type } from 'jquery';

export const AncorPrimaryButton = ({
    buttonText,
    redirectPage,
    style,
    icon,
    className,
    target,
}) => {
    return (
        <Link
            // icon={icon}
            style={style}
            target={target}
            href={redirectPage}
            className={`${styles.primary_btn} ${className}`}
        >
            {icon}
            {buttonText}
        </Link>
    );
};

export const AncorSecoundaryButton = ({
    buttonText,
    redirectPage,
    style,
    icon,
    target,
    onClick,
    className,
}) => {
    return (
        <Link
            // icon={icon}
            style={style}
            href={redirectPage}
            target={target}
            className={`${styles.secoundary_btn} ${className}`}
        >
            {icon}
            {buttonText}
        </Link>
    );
};

export const SecoundaryButton = ({
    buttonText,
    type,
    style,
    icon,
    onClick,
    className,
}) => {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            className={`${styles.secoundary_btn} ${className}`}
        >
            {icon}{buttonText}
        </button>

    );
};


export const AncorTernaryButton = ({
    buttonText,
    redirectPage,
    style,
    icon,
    className,
    target,
}) => {
    return (
        <Link
            // icon={icon}
            style={style}
            target={target}
            href={redirectPage}
            className={`${styles.ternary_btn} ${className}`}
        >
            {icon}
            {buttonText}
        </Link>
    );
};
