import React from "react";

const LocatbusinessSchema = () => (
  <React.Fragment>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Adventure Resort",
            "image": "https://www.adventureresortkufri.com/images/home-banner.png",
            "@id": "",
            "url": "https://www.adventureresortkufri.com",
            "telephone": "9318192601",
            "priceRange": "$$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Adventure Resort New Kufri Gallue Hills",
              "addressLocality": "New Kufri",
              "postalCode": "171209",
              "addressCountry": "IN"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 31.0891785,
              "longitude": 77.28672519999999
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "09:00",
              "closes": "21:00"
            },
            "sameAs": [
              "https://www.facebook.com/profile.php?id=100063787581492",
              "https://www.instagram.com/adventureresorts/",
              "https://www.youtube.com/channel/UCx6KSZPSkcM_OIqWkJCPzvA"
            ]

          }
        )
      }}
    />
  </React.Fragment>
);
export default LocatbusinessSchema;
