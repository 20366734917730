import Image from 'next/image'
import styles from "./RidesandactivitiesSubmenu.module.scss"
import Link from 'next/link';
import { AncorPrimaryButton } from '../Buttons/Buttons';

export default function RidesandactivitiesSubmenu() {
    return (
        <div className={`${styles.activity_nav_child} row`}>
            <div className="col-md-6">
                <div className='row'>
                    <div className='col-md-6'>
                        <Link href='/rides-and-activities/go-karting' className={styles.activity_nav_items_left}>
                            <div className={styles.img_box}>
                                <Image src='/images/activitiesimages/karting.png' fill alt="Go Karting"></Image>
                            </div>
                            <p>Go Karting</p>
                        </Link>
                    </div>
                    <div className='col-md-6'>
                        <Link href='/rides-and-activities/zip-line' className={styles.activity_nav_items_left}>
                            <div className={styles.img_box}>
                                <Image src='/images/activitiesimages/zip-line.png' fill alt="Zip Line"></Image>
                            </div>
                            <p>Zip Line</p>
                        </Link>
                    </div>
                    <div className='col-md-6'>
                        <Link href='/rides-and-activities/sky-swinger' className={styles.activity_nav_items_left} style={{ marginBottom: '0' }}>
                            <div className={styles.img_box}>
                                <Image src='/images/activitiesimages/sky-swinger.png' fill alt="Sky Swinger"></Image>
                            </div>
                            <p>Sky Swinger</p>
                        </Link>
                    </div>
                    <div className='col-md-6'>
                        <Link href='/rides-and-activities/frisbee' className={styles.activity_nav_items_left} style={{ marginBottom: '0' }}>
                            <div className={styles.img_box}>
                                <Image src='/images/activitiesimages/frisbee.png' fill alt="Frisbee"></Image>
                            </div>
                            <p>Frisbee</p>
                        </Link>
                    </div>

                    <div className='col-md-12'>
                        <AncorPrimaryButton
                            redirectPage={'/rides-and-activities'}
                            className={'margin_0_auto'}
                            buttonText={'Explore All'}
                            style={{ marginTop: '10px' }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6" style={{ borderLeft: "1px solid #00AA49" }}>

                <Link href='https://www.snowkingdomkufri.com/' target='_blank' className={styles.activity_nav_items_right}>
                    <img src="/images/snow-kingdom/snow-kingdom.png" alt='Snow Kingdom Kufri' />
                    <div className={styles.activity_text}>
                        <p>Snow Kingdom Kufri</p>
                        <span>EXPLORE</span>
                    </div>
                </Link>
                {/* <Link href='/rides-and-activities' className={`${styles.activity_nav_items_right_btn} common_btn`}>Explore All</Link> */}
            </div>
        </div>
    )
}


//  <div className='row'>
//                     <div className='col-md-6'>
//                      <h5 className={styles.activities_heading}>Outdoor Activities</h5>
//                         <Link href='/rides-and-activities/go-karting' className={styles.activity_nav_items_left}>
//                             <div className={styles.img_box}>
//                                 <Image src='/images/karting-nav.png' height={80} width={80} alt="Hotel Twin Tower"></Image>
//                             </div>
//                             <p>Go Karting</p>
//                         </Link>
//                         <Link href='/rides-and-activities/zip-line' className={styles.activity_nav_items_left}>
//                             <div className={styles.img_box}>
//                                 <Image src='/images/zip-line-nav.png' height={80} width={80} alt="Hotel Twin Tower"></Image>
//                             </div>
//                             <p>Zip Line</p>
//                         </Link>
//                         <Link href='/rides-and-activities/sky-swinger' className={styles.activity_nav_items_left}>
//                             <div className={styles.img_box}>
//                                 <Image src='/images/sky-swinger-nav.png' height={80} width={80} alt="Hotel Twin Tower"></Image>
//                             </div>
//                             <p>Sky Swinger</p>
//                         </Link>
//                         {/* new added */}
//                         <Link href='/rides-and-activities/frisbee' className={styles.activity_nav_items_left}>
//                             <div className={styles.img_box}>
//                                 <Image src='/images/activitiesimages/frisbee.png' height={80} width={80} alt="Hotel Twin Tower"></Image>
//                             </div>
//                             <p>Frisbee</p>
//                         </Link>
//                     </div>
//                     <div className='col-md-6'>
//                         <h5 className={styles.activities_heading}>Indoor Activities</h5>
//                         <Link href='/rides-and-activities/smash-car' className={styles.activity_nav_items_left}>
//                             <div className={styles.img_box}>
//                                 <Image src='/images/activitiesimages/smash-car.png' height={80} width={80} alt="Hotel Twin Tower"></Image>
//                             </div>
//                             <p>Smash Car</p>
//                         </Link>
//                         <Link href='/rides-and-activities/5d-theatre' className={styles.activity_nav_items_left}>
//                             <div className={styles.img_box}>
//                                 <Image src='/images/activitiesimages/5d-cinema.png' height={80} width={80} alt="Hotel Twin Tower"></Image>
//                             </div>
//                             <p>5D Theater</p>
//                         </Link>
//                         <Link href='/rides-and-activities/haunted-house' className={styles.activity_nav_items_left}>
//                             <div className={styles.img_box}>
//                                 <Image src='/images/activitiesimages/haunted-house.png' height={80} width={80} alt="Hotel Twin Tower"></Image>
//                             </div>
//                             <p>Haunted House</p>
//                         </Link>
//                         <Link href='/rides-and-activities/rifle-shooting' className={styles.activity_nav_items_left}>
//                             <div className={styles.img_box}>
//                                 <Image src='/images/activitiesimages/paintball.png' height={80} width={80} alt="Hotel Twin Tower"></Image>
//                             </div>
//                             <p>Rifle Shooting</p>
//                         </Link>
//                     </div>
//                 </div> 