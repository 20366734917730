import Image from "next/image";
import Link from "next/link";
import styles from './AnantaSubmenu.module.scss'

export default function AnantaSubmenu() {
    return (
        <div className={`${styles.ananta_nav_child} row`}>
            {/* <div className="col-md-8">
                <div className="row"> */}
            <div className="col-md-6">
                <Link href='https://ananta.adventureresortkufri.com/wedding' target='_blank' className={styles.activity_nav_items_left}>
                    <Image src='/images/snow-kingdom/wedding.jpg' fill alt="Wedding at Ananta by adventure resort"></Image>
                    <div className={styles.activity_text}>
                        <span>Wedding & Celebrations</span>
                    </div>
                </Link>
            </div>
            <div className="col-md-6">
                <Link href='https://ananta.adventureresortkufri.com/meeting' target='_blank' className={styles.activity_nav_items_left}>
                    <Image src='/images/snow-kingdom/meeting.png' fill alt="Wedding at Ananta by adventure resort"></Image>
                    <div className={styles.activity_text}>
                        <span>Meeting & Events</span>
                    </div>
                </Link>
            </div>
            {/* </div>
            </div> */}
            {/* <div className="col-md-4" style={{ borderLeft: "1px solid #00AA49" }}>
                <div className={styles.activity_nav_items_right_btn}>
                    <Link href='https://ananta.adventureresortkufri.com' className="common_btn ">Explore all</Link>
                </div>
            </div> */}
        </div>
    );
}