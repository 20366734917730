import React, { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import styles from "../styles/Components/Header.module.scss";
import Schema from "./Schema";
import Head from "next/head";
import RidesandactivitiesSubmenu from "./Reusable/RidesandactivitiesSubmenu/RidesandactivitiesSubmenu";
import dynamic from 'next/dynamic'
import StaySubmenu from "./Reusable/StaySubmenu/StaySubmenu";
import AnantaSubmenu from "./Reusable/AnantaSubmenu/AnantaSubmenu";
import HeatMap from "./Schema/Resortschema/heat-map";
import { AncorSecoundaryButton } from "./Reusable/Buttons/Buttons";

const Cart = dynamic(() => import('./Reusable/Cart/Cart'), { ssr: false });

const Header = () => {
    const [navbar, setNavbar] = useState(false);
    const [isactive, setIsactive] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState(null);

    const handleIsActive = () => {
        console.log('clicked');
        setIsactive(!isactive);
    };

    const nav_links = [
        {
            title: "About Us",
            hyperLink: "/about-us"
        },
        {
            title: "Rides & Activities",
            hyperLink: "/rides-and-activities",
            subMenus: [
                {
                    title: 'Go-Karting',
                    hyperLink: '/rides-and-activities/go-karting'
                },
                {
                    title: 'Zipline',
                    hyperLink: '/rides-and-activities/zip-line'
                },
                {
                    title: 'Sky Swinger',
                    hyperLink: '/rides-and-activities/sky-swinger'
                },
                {
                    title: 'Frisbee',
                    hyperLink: '/rides-and-activities/frisbee'
                },
                {
                    title: 'Swing It',
                    hyperLink: '/rides-and-activities/swing-it'
                },
                {
                    title: 'Explore All',
                    hyperLink: '/rides-and-activities'
                },
            ]
        },
        {
            title: "Ananta",
            target: "_blank",
            hyperLink: "https://ananta.adventureresortkufri.com/"
        },
        {
            title: "Stay",
            target: "_blank",
            hyperLink: "https://www.hoteltwintowers.com/"
        },
        {
            title: "Dining",
            hyperLink: "/restaurant"
        },
        {
            title: "Blogs",
            hyperLink: "/blogs"
        },
        {
            title: "Stores",
            hyperLink: "/stores"
        },
        {
            title: "Group Tours",
            hyperLink: "/educational-tour"
        },
        // {
        //     title: "Contact Us",
        //     hyperLink: "/contact-us"
        // },
    ];

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    const handleSubmenuToggle = (index) => {
        if (activeSubmenu === index) {
            setActiveSubmenu(null);
        } else {
            setActiveSubmenu(index);
        }
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", changeBackground);
    };

    useEffect(() => {
        if (isactive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isactive]);

    return (
        <>
            <Head>
                <meta
                    name="google-site-verification"
                    content="8OS3LTkKMn2EPUmMHPVK_5i8Zz2_AWqm4FauMTnIleE"
                />
            </Head>

            <header className={navbar ? "header header_fixed" : "header"}>
                <div className={styles.header_top}>
                    <div className="container">
                        <div className={`${styles.header_top_main} row`}>
                            <div className={`${styles.header_top_left} col-md-2`}>
                                <div className={styles.top_call_icon}>
                                    <Link href="tel:9318192601" className='ananta_btn'>
                                        <Image className="ananta_icon" src='/icons/call-white.svg' width={15} height={15} priority={true} alt='Call Icon'></Image>
                                        <span className="ananta_hidden">+91 9318192601</span>
                                    </Link>
                                </div>
                                <div className={styles.top_logo}>
                                    <Link href="/">
                                        <Image src="/icons/logo.svg" alt="Logo" width={150} height={38} priority={true} />
                                    </Link>
                                </div>
                            </div>

                            <div className={`${styles.header_top_center} col-md-8 text-center`}>
                                {navbar ? <div className={styles.scrolled_header}>
                                    <ul>
                                        <li className={styles.nav_item}>
                                            <Link href="/about-us" className={styles.nav_link}>About US</Link>
                                        </li>
                                        <li className={`${styles.nav_item} ${styles.activity_nav_dropdown}`}>
                                            <Link href="/rides-and-activities" className={styles.nav_link}>Rides & Activities</Link>
                                            <div className={styles.activity_nav_items}>
                                                <RidesandactivitiesSubmenu />
                                            </div>
                                        </li>
                                        <li className={`${styles.nav_item} ${styles.activity_nav_dropdown}`}>
                                            <Link href="https://www.hoteltwintowers.com/" target="_blank" className={styles.nav_link}>Stay</Link>
                                            <div className={styles.activity_nav_items}>
                                                <StaySubmenu />
                                            </div>
                                        </li>
                                        <li className={styles.nav_item}>
                                            <Link href='/' className={styles.nav_link}><Image src="/icons/adventure-icon.svg" alt="Logo" width={40} height={40} /></Link>
                                        </li>
                                        <li className={`${styles.nav_item} ${styles.activity_nav_dropdown}`}>
                                            <Link href="https://ananta.adventureresortkufri.com/" target="_blank" className={styles.nav_link}>Ananta</Link>
                                            <div className={styles.activity_nav_items}>
                                                <AnantaSubmenu />
                                            </div>
                                        </li>
                                        <li className={styles.nav_item}>
                                            <Link href="/restaurant" className={styles.nav_link}>Dining</Link>
                                        </li>

                                        <li className={styles.nav_item}>
                                            <Link href="/stores" className={styles.nav_link}>Stores</Link>
                                        </li>
                                    </ul>
                                </div>
                                    : <div><Link href='/'><Image src="/icons/logo.svg" alt="Logo" width={198} height={38} priority={true} /></Link></div>}
                            </div>

                            <div className={`${styles.header_top_right} col-md-2`}>
                                <Cart />
                                <div>
                                    <AncorSecoundaryButton
                                        redirectPage={'/tickets'}
                                        className={'margin_0_auto'}
                                        buttonText={'BUY TICKETS'} />
                                    {/* <Link href="/tickets" className={navbar ? "common_btn ternary_btn" : "common_btn"}>BUY TICKETS</Link> */}
                                </div>
                            </div>

                            <div className={styles.header_toggle}>
                                <Cart />

                                <button onClick={handleIsActive} className={styles.toogle_btn}>
                                    {/* <Image
                                        src="/icons/toggle.svg"
                                        width={18}
                                        height={20}
                                        alt="Logo"
                                        priority={true}
                                    ></Image> */}

                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
                                        <path d="M2 2H22M2 12H22" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {navbar ? ('') : <div
                    className={`${styles.header_bottom}`}
                >
                    <div className="container">
                        <div className={styles.header_flex}>
                            <div className={styles.header_right}>
                                <ul className="navbar-nav">
                                    <li className={styles.nav_item}>
                                        <Link href="/about-us" className={styles.nav_link}>About US</Link>
                                    </li>
                                    <li className={`${styles.nav_item} ${styles.activity_nav_dropdown}`}>
                                        <Link href="/rides-and-activities" className={styles.nav_link}>Rides & Activities</Link>
                                        <div className={styles.activity_nav_items}>
                                            <RidesandactivitiesSubmenu />
                                        </div>
                                    </li>
                                    <li className={`${styles.nav_item} ${styles.activity_nav_dropdown}`}>
                                        <Link href="https://www.hoteltwintowers.com/" target="_blank" className={styles.nav_link}>Stay</Link>
                                        <div className={styles.activity_nav_items}>
                                            <StaySubmenu />
                                        </div>
                                    </li>
                                    <li className={`${styles.nav_item} ${styles.activity_nav_dropdown}`}>
                                        <Link href="https://ananta.adventureresortkufri.com/" target="_blank" className={styles.nav_link}>Ananta</Link>
                                        <div className={styles.activity_nav_items}>
                                            <AnantaSubmenu />
                                        </div>
                                    </li>
                                    <li className={styles.nav_item}>
                                        <Link href="/restaurant" className={styles.nav_link}>Dining</Link>
                                    </li>
                                    <li className={styles.nav_item}>
                                        <Link href="/stores" className={styles.nav_link}>Stores</Link>
                                    </li>


                                    {/* <li className={`${styles.nav_item} ${styles.grouptour_nav_dropdown}`}>
                                        <Link href="/stores" className={styles.nav_link}>Group Tours</Link>
                                        <div className={styles.groups_nav_items}>
                                            <AnantaSubmenu />
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                            <button className={styles.header_toggle}>
                                <Image width={30} height={30} alt="logo" src="/icons/call-white.svg" />
                            </button>
                        </div>
                    </div>
                </div>}
            </header>

        
            <div className={`${styles.mobile_header} ${isactive ? styles.active : ''}`}>
                <div className={`${styles.mobile_header_cont} ${isactive ? styles.active : ''}`}>
                    <div className={styles.mobile_header_div}>
                        <div className="logo">
                            <Link className={styles.navbar_brand} href="/">
                                <Image src="/icons/colored-logo.svg" alt="Logo" width={150} height={38} />
                            </Link>
                        </div>
                        <div>
                            <button className={styles.navbar_cut_btn} onClick={handleIsActive}>
                                {/* <Image
                                    src="/icons/close.svg"
                                    width={16}
                                    height={16}
                                    alt="Logo"
                                ></Image> */}

                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                    <path d="M1.82178 1L17.8218 17" stroke="#333333" strokeWidth="2" />
                                    <path d="M1.68066 17L17.6806 1.00002" stroke="#333333" strokeWidth="2" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className={styles.mobile_header_links}>
                        <nav>
                            <div>
                                <div className={styles.res_header_links}>
                                    <ul className={styles.mobile_view_ul}>
                                        {nav_links.map((item, index) => (
                                            <li className={styles.mobile_view_lists} key={index}>
                                                <div onClick={() => handleSubmenuToggle(index)}>
                                                    {item.subMenus ? (
                                                        <div>
                                                            <span className={`${styles.mobile_view_lists_item} ${styles.have_submenu_items_list}`}
                                                                style={activeSubmenu === index ? { color: '#0EAA49' } : { color: '#333333' }}
                                                            >
                                                                {item.title}
                                                                {activeSubmenu === index ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                                                    <path d="M9 5L5 1L1 5" stroke="#00AA49" strokeWidth="1.2" />
                                                                </svg> :
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                                                        <path d="M1 1L5 5L9 1" stroke="#00AA49" strokeWidth="1.2" />
                                                                    </svg>}
                                                            </span>
                                                            {activeSubmenu === index && (
                                                                <ul className={styles.mobile_view_lists_submenus_ul}>
                                                                    {item.subMenus.map((subItem, subIndex) => (
                                                                        <li key={subIndex}>
                                                                            <Link href={subItem.hyperLink} onClick={handleIsActive} className={styles.mobile_view_lists_subitem}>{subItem.title}</Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <Link href={item.hyperLink} onClick={handleIsActive} className={styles.mobile_view_lists_item}>{item.title}</Link>
                                                    )}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className={styles.header_right_btn}>
                                    <Link href="/tickets" className="common_btn" onClick={handleIsActive}>BUY TICKETS</Link>
                                    <Link href="tel:9317192601" className={styles.mobile_call}>
                                        <Image src="/images/sigimages/call.png" height={12} width={14} alt="Call Icon"></Image>
                                        &nbsp; Call Now
                                    </Link>
                                </div>
                                <Link
                                    href="https://www.youtube.com/@adventureresorts"
                                    target="_blank"
                                >
                                    <Image
                                        src="/icons/youtube.svg"
                                        width={20}
                                        height={20}
                                        className="mx-2"
                                        alt="Youtube Icon"
                                    ></Image>
                                </Link>
                                <Link
                                    href="https://www.facebook.com/profile.php?id=100063787581492"
                                    target="_blank"
                                >
                                    <Image
                                        src="/icons/fb.svg"
                                        width={20}
                                        height={20}
                                        className="mx-2"
                                        alt="Facebook Icon"
                                    ></Image>
                                </Link>
                                <Link
                                    href="https://www.instagram.com/adventureresorts/"
                                    target="_blank"
                                >
                                    <Image
                                        src="/icons/insta.svg"
                                        width={20}
                                        height={20}
                                        className="mx-2"
                                        alt="Instagram Icon"
                                    ></Image>
                                </Link>
                            </div>
                        </nav>
                    </div>
                </div >
            </div>
        </>
    )
}

export default Header;
