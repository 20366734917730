import React from "react";

const RestaurantFaq = () => (
    <React.Fragment>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "Can I make dining reservations in advance?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, at Adventure Resorts Kufri, we offer the option to make dining reservations in advance. Whether you're looking to dine at our exquisite on-site restaurant or enjoy a special meal at one of our wonderful dining venues, we recommend making reservations ahead of time to guarantee your desired dining time and ensure a seamless experience."
                            }
                        }, {
                            "@type": "Question",
                            "name": "Do you offer any meal packages or all-inclusive dining options?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, we offer various meal packages and all-inclusive dining options to ensure that you have a hassle-free dining experience during your stay with us. Whether you're looking for a comprehensive all-inclusive package that covers breakfast, lunch, dinner, and snacks, or a specific meal package that includes select meals, we have options to suit your needs. For more information about our meal packages and all-inclusive dining options, we recommend contacting our reservations team or visiting our website for the latest offerings and details."
                            }
                        }, {
                            "@type": "Question",
                            "name": "Can I request a special celebration or romantic dinner setup?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Certainly! At Adventure Resort Kufri, we are pleased to offer special celebration and romantic dinner setups upon request. Whether it's a birthday, anniversary, or a romantic evening, our team will go the extra mile to create a memorable and enchanting dining experience for you and your loved ones. Please contact our booking team to discuss your specific requirements."
                            }
                        }, {
                            "@type": "Question",
                            "name": "Are there any kid-friendly dining options or childrens menus?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, we offer kid-friendly dining options and a dedicated menu to cater to our young guests. Our menus feature a variety of delicious and nutritious options designed to satisfy even the pickiest eaters. We strive to make dining a delightful experience for the whole family at Adventure Resort Kufri."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What happens if I do not reconfirm my dining booking?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "We highly recommend reconfirming your dining booking to ensure a seamless experience. If you fail to reconfirm your reservation, there is a possibility that your booking may be released to accommodate other guests. To avoid any inconvenience, we encourage guests to contact our reservations team or the restaurant directly to reconfirm their dining bookings."
                            }
                        }]
                    }
                )
            }}
        />
    </React.Fragment>
);
export default RestaurantFaq;