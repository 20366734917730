import React from "react";

const OrganisationSchema = () => (
  <React.Fragment>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "AmusementPark",
            "name": "Adventure Resort Kufri",
            "image": "https://www.adventureresortkufri.com/images/activitiesimages/sky-swinger.png",
            "@id": "",
            "url": "https://www.adventureresortkufri.com",
            "telephone": "+91 9318192601",
            "priceRange": "400",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Adventure Resort Kufri",
              "addressLocality": "Gallue Hills, New, Kufri, Himachal Pradesh",
              "postalCode": "171209",
              "addressCountry": "IN"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 31.089696676165207,
              "longitude": 77.28607997614543
            },
            "sameAs": [
              "https://www.facebook.com/adventureresortsnew/",
              "https://www.instagram.com/adventureresorts/",
              "https://www.youtube.com/@adventureresorts"
            ]
          }
        )
      }}
    />
  </React.Fragment>
);
export default OrganisationSchema;