import React from "react";

const EducationalPackagesFaq = () => (
  <React.Fragment>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What age groups are suitable for visiting Adventure Resorts in Kufri?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Adventure Resorts caters to a wide range of age groups, from young children to teenagers and adults. The variety of activities available ensures there's something for everyone, from thrilling rides like go-karting and zip-lining to more relaxed options like indoor activities and dining experiences."
              }
            }, {
              "@type": "Question",
              "name": "Are there special rates or packages available for student groups?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, Adventure Resorts offers special rates and packages tailored specifically for student groups. These packages often include discounts on activities, dining, and accommodation, making it an affordable and enjoyable experience for school outings or educational trips."
              }
            }, {
              "@type": "Question",
              "name": "What safety measures are in place for student groups participating in outdoor activities like go-karting and ziplining?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Safety is our top priority at Adventure Resorts. Trained staff members supervise all outdoor activities, and safety equipment is provided for participants. Additionally, all rides and courses undergo regular maintenance and inspection to ensure they meet safety standards."
              }
            }, {
              "@type": "Question",
              "name": "Is there accommodation available for overnight stays during our visit?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, Adventure Resorts offers comfortable hotel accommodation for overnight stays. Our hotel amenities include spacious rooms, dining options, and access to various recreational facilities. It's the perfect option for extending your visit and exploring more of the beautiful surroundings."
              }
            }, {
              "@type": "Question",
              "name": "Can we bring our own food for picnics or outdoor dining during our visit?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "While Adventure Resorts has a variety of dining options available on-site, including spacious dining areas, we also understand the appeal of picnics and outdoor dining. Guests are welcome to bring their own food for picnics in designated areas within the resort premises, surrounded by the serene natural beauty of the forested surroundings."
              }
            }]
          }
        )
      }}
    />
  </React.Fragment>
);
export default EducationalPackagesFaq;