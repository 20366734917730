import React from 'react'

const ViewTicketsContent = () => (
    <React.Fragment>
        <script dangerouslySetInnerHTML={{
            __html: `
                     fbq('track', 'TicketsView');
            ` }}
        />
        <noscript dangerouslySetInnerHTML={{
            __html: `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=1449207648972035&ev=PageView&noscript=1" />` }}
        />
    </React.Fragment>
);
export default ViewTicketsContent;