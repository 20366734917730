import Image from "next/image";
import Link from "next/link";
import styles from "./StaySubmenu.module.scss"

export default function StaySubmenu() {
    return (
        <div className={`${styles.activity_nav_child} row`}>
            <div className="col-md-7">
                <div className="row">
                    <div className="col-md-6">
                        {/* <div className={styles.activity_nav_items_left}>
                            <div className={`${styles.img_box} ${styles.activity_nav_items_right}`}>
                                <Image src='/images/stay3.png' height={120} width={250} alt="Hotel Twin Tower"></Image>
                            </div>
                            <Link href="https://www.hoteltwintowers.com/rooms-and-suites/executive-room" target="_blank">Executive Room</Link>
                        </div> */}

                        <Link href='https://www.hoteltwintowers.com/rooms-and-suites/executive-room' className={styles.activity_nav_items_left}>
                            <div className={styles.img_box}>
                                <Image src='/images/executive-room.png' fill alt="Frisbee"></Image>
                            </div>
                            <p>Executive Room</p>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <Link href='https://www.hoteltwintowers.com/rooms-and-suites/premier-room' className={styles.activity_nav_items_left}>
                            <div className={styles.img_box}>
                                <Image src='/images/premier-room.png' fill alt="Frisbee"></Image>
                            </div>
                            <p>Premier Room</p>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <Link href='https://www.hoteltwintowers.com/rooms-and-suites/deluxe-room' className={styles.activity_nav_items_left} style={{ marginBottom: '0' }}>
                            <div className={styles.img_box}>
                                <Image src='/images/deluxe-room.png' fill alt="Frisbee"></Image>
                            </div>
                            <p>Deluxe Room</p>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <Link href='https://www.hoteltwintowers.com/rooms-and-suites/family-suite-room' className={styles.activity_nav_items_left} style={{ marginBottom: '0' }}>
                            <div className={styles.img_box}>
                                <Image src='/images/family-suite-room.png' fill alt="Frisbee"></Image>
                            </div>
                            <p>Family Suite</p>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="col-md-5" style={{ borderLeft: "1px solid #00AA49" }}>
                <Link href='https://www.hoteltwintowers.com/rooms-and-suites' target='_blank' className={styles.activity_nav_items_right}>
                    <img src="/images/snow-kingdom/hoteltwintower.png" alt='Snow Kingdom Kufri' />
                    <div className={styles.activity_text}>
                        <p>The Twin Towers Hotels</p>
                        <span>EXPLORE</span>
                    </div>
                </Link>
            </div>
        </div>
    );
}
