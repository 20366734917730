import React from "react";

const BreadcrumbSchema = () => (
    <React.Fragment>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                    {
                        "@context": "https://schema.org/",
                        "@type": "BreadcrumbList",
                        "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Our Rides & Activites",
                                "item": "https://www.adventureresortkufri.com/rides-and-activities"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Exclusive Dining Experience",
                                "item": "https://www.adventureresortkufri.com/restaurant"
                            },
                            {
                                "@type": "ListItem",
                                "position": 4,
                                "name": "Contact Us",
                                "item": "https://www.adventureresortkufri.com/contact-us"
                            }
                        ]
                    }
                )
            }}
        />
    </React.Fragment>
);
export default BreadcrumbSchema;