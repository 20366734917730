import React from "react";
import Head from "next/head";
import styles from "./Loader.module.scss";

export default function Loader() {
  return (
    <>
      {/* <Head>
                <link rel="icon" href="/favicon.png" />
            </Head> */}
      {/* <section className={styles.loader_main}>
                <div className={styles.loader_sec}>
                    <img src="/images/loader.gif" width="100%" height="100%" alt="Loading" /> */}
      {/* <div className={`${styles.loader} ${styles.loader_3} `}>
                        <div className={`${styles.dot} ${styles.dot1} `}></div>
                        <div className={`${styles.dot} ${styles.dot2} `}></div>
                        <div className={`${styles.dot} ${styles.dot3} `}></div>
                    </div>
                    <div className={styles.loading_text}></div> */}
      {/* </div>
            </section> */}
      <section className={styles.loader_main}>
        <div className={styles.loader_sec}>
          <video width="100%" height="100%" autoPlay loop muted playsInline>
            <source src="/video/loader.mp4" type="video/mp4" />
          </video>
        </div>
      </section>
    </>
  );
}
